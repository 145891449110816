import { useMemo } from "react";

import { CustomFieldType } from "~/types/custom-field";

export const useAllCustomFields = (
  customFieldType?: CustomFieldType,
  customFieldOptions?: EVA.Core.Management.GetCustomFieldsResponse["CustomFieldOptions"],
  customFieldMetadata?: EVA.Core.Management.GetCustomFieldMetadataResponse,
) => {
  const customFields = useMemo(
    () =>
      customFieldOptions && customFieldType !== undefined
        ? Object.keys(customFieldOptions).reduce<EVA.Core.CustomFieldResponse[]>(
            (accumulator, current) => {
              const customFieldID = parseInt(current);
              const options = customFieldOptions[customFieldID];

              const metaData = customFieldMetadata?.CustomFieldsMetadata?.[customFieldType]?.find(
                (customField) => customField.CustomFieldID === customFieldID,
              );

              if (isNaN(customFieldID) || !metaData) {
                return accumulator;
              }

              return [
                ...accumulator,
                {
                  ...metaData,
                  IsEditableByUser: options.IsEditable ?? false,
                  Options: options,
                },
              ];
            },
            [],
          )
        : [],
    [customFieldMetadata?.CustomFieldsMetadata, customFieldOptions, customFieldType],
  );

  return customFields;
};
